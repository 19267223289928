import React from "react";
import Section from "../../Components/Section";
import woodBanner from "../../Assets/Images/Branding/Wood_Header.png";
import fishFossil from "../../Assets/Images/Branding/fish_fossil.png";
import Footprints from "./FootPrintsComponents/FootPrints";
import FootprintsTwo from "./FootPrintsComponents/FootPrintsTwo";
import FootprintsThree from "./FootPrintsComponents/FootPrintsThree";
import FootprintsFour from "./FootPrintsComponents/FootPrintsFour";
import AboutFrame from "../../Assets/Images/Branding/About_Frame.png";
import "./Home.scss";

const AboutPage = () => {
  return (
    <Section id="about">
      <div className="about-flex">
        <div className="wood-banner-about">
          <img src={AboutFrame} alt="About" className="banner-img" />
        </div>
        <div className="about-content">
          <div className="text-diagonal">
            <p className="about-text">
              Inspired by Jurassic Park,
              <strong> Hacklytics 2025: Epoch of the Dinosaurs</strong> takes
              you through a journey of the prehistoric ages filled with
              extraordinary species and hidden stories.
            </p>
            <p className="about-text">
              Investigate these adventurous challenges through the intersection
              of technology and innovation to solve real-world problems.
            </p>
            <p className="about-text">
              We hope looking into the past inspires you to find solutions to
              the profound problems of the present and future pertaining to our{" "}
              <strong>four tracks</strong>.
            </p>
            <p className="about-text">
              Showcase your diverse skillsets and unique perspectives that'll
              help you <strong>saur</strong> at this hackathon.
            </p>
          </div>
        </div>
        <img src={fishFossil} alt="Fish Fossil" className="fish-fossil" />
        <Footprints count={17} /> <FootprintsTwo count={15} />{" "}
        <FootprintsThree count={12} /> <FootprintsFour count={9} />{" "}
        {/* <Footprints count={13} style={{ top: "5em" }} />{" "} */}
        {/* Second set of footprints with top margin */}
        {/* Use the new Footprints component with a count prop */}
      </div>
      <a className="continue-reading" href="#whatis">
        <span className="text">What is Hacklytics?</span>
        <span className="arrow">↓</span>
      </a>
    </Section>
  );
};

export default AboutPage;
