import hacklytics2025logo from "../../Assets/Images/Branding/Hacklytics 2025 Logo.svg";
import React from "react";
import Section from "../../Components/Section";
import Faq from "../../Components/FAQ";

import "./Home.css";

const FAQPage = () => {
  return (
    <Section id="faq" Coordinates="False">
      <div className="background-container"></div>
      <div className="faq-flex">
        <h1 className="major">FAQ</h1>
        <div className="faq-grid">
          <Faq identifier="a" question="Who can register?">
            Any student currently enrolled in a University above the age of 18.
            For any discrepancies, please reach out.
          </Faq>
          <Faq identifier="b" question="Where and when is it held?">
            February 7th - February 9th at the{" "}
            <a href="https://goo.gl/maps/W7uum6WcHQ6bTrJXA">
              Klaus Advanced Computing Building
            </a>
          </Faq>
          <Faq
            identifier="l"
            question="How do I apply for Travel Reimbursements?"
          >
            Please check back soon for updates. We appreciate your patience and
            look forward to seeing you at Hacklytics 2025!
          </Faq>
          <Faq identifier="c" question="Can you participate virtually">
            Unfortunately, no, Hacklytics 2025 is only in person this year.
            Sponsors want to come see you work on your projects in person!
          </Faq>
          <Faq identifier="d" question="What is a data science hackathon?">
            A datathon is a specific type of hackathon that focuses on data
            science. You can choose any datasets, programming languages, APIs,
            or algorithms you'd like to create visualizations, develop models,
            derive insights, and do anything you believe to be impactful! The
            sky's the limit!
          </Faq>
          <Faq identifier="e" question="How many people per team?">
            A maximum of 4 members per team. However, you’re allowed to work
            with fewer members as well!
          </Faq>
          <Faq identifier="f" question="What if I don’t have a team?">
            Lots of people come in without teams! You can find people to form a
            team with during our team-building event or through our Discord.
          </Faq>
          <Faq identifier="g" question="Is the event free?">
            Yes! We also provide food and other goodies if you are in-person.
          </Faq>
          <Faq identifier="h" question="Is this event beginner friendly?">
            Yes, we encourage beginners to apply to expand their knowledge! We
            will have tons of skill-building workshops and experienced mentors
            to help out with projects at the event. Overall, Hacklytics will be
            a very enriching experience regardless of how experienced you are!
          </Faq>
          <Faq identifier="i" question="How are applicants selected?">
            We evaluate your applications by looking into your interest in
            participating and passion in the field of data!
          </Faq>
          <Faq
            identifier="j"
            question="What, other than coding, is going to happen at this event?"
          >
            Speaker talks, workshops, social events and a lot more!
          </Faq>
          <Faq identifier="k" question="How do I get in touch?">
            Reach out to us at{" "}
            <a href="mailto:info@hacklytics.io">info@hacklytics.io</a>
          </Faq>
        </div>
      </div>
      <a className="continue-reading" href="#speakers">
        <span className="text">Speakers</span>
        <span className="arrow">↓</span>
      </a>
    </Section>
  );
};

export default FAQPage;
