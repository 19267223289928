import React from "react";
import Section from "../../Components/Section";
import vines from "../../Assets/Images/Branding/Vines.svg";
import car from "../../Assets/Images/Branding/Car.svg";
import dinosaurbones from "../../Assets/Images/Branding/Dinosaur_Bones.png";

import "./Home.css";

const WhenWherePage = () => {
  return (
    <Section id="whenwhere">
      <img src={dinosaurbones} alt="Vines" className="dbones" />
      <img src={car} alt="Car" className="car-img" />
      <div className="whenwhere-flex">
        <h2 className="major">When and Where is Hacklytics?</h2>
        <div className="split-flex">
          <div className="info-column">
            <h3 className="when-where-header">When:</h3>
            <p className="mini">February 7 - 9, 2025</p>
            <h3 className="when-where-header">Where:</h3>
            <p className="mini">
              The{" "}
              <a href="https://goo.gl/maps/W7uum6WcHQ6bTrJXA">
                Klaus Advanced Computing Building
              </a>{" "}
              on Georgia Tech Campus
            </p>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212247.46455110525!2d-84.67596646718748!3d33.777029200000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b781ec0ab42ea5%3A0x16eec927f37b40ec!2sKlaus%20Advanced%20Computing%20Building!5e0!3m2!1sen!2sus!4v1656647278486!5m2!1sen!2sus"
              width="300"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Klaus Advanced Computing Building"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <a className="continue-reading" href="#about">
        <span className="text">continue</span>
        <span className="arrow">↓</span>
      </a>
    </Section>
  );
};

export default WhenWherePage;
